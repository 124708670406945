import React, { useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { func } from 'prop-types'

import ActionHeaderContext from 'context/ActionHeaderContext'

import { getCustomer } from 'store/customers/actions'
import * as customerSelectors from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'
import * as territorySelectors from 'store/territories/selectors'

import Button from 'components/button/Button'
import Container from 'components/Container'
import EmptyState from 'components/EmptyState'
import GlobalAlert from 'components/GlobalAlert'
import LoadingCard from 'components/LoadingCard'
import SAQTable from 'components/SAQTable'

import { DATAKEY_TYPES, SECTOR_LEVELS } from 'utils/constants'
import { createDataKey, getErrorMessage } from 'utils/helpers'

const SAQList = ({ getCustomer }) => {
  const navigate = useNavigate()
  const [error, setError] = useState()
  const { sectorId: customerId, sectorType: sectorLevel } = useParams()
  const { addAction } = useContext(ActionHeaderContext)
  const dataKey = createDataKey(DATAKEY_TYPES.CUSTOMER, { customerId })

  const isLoading = useSelector((state) => isDataKeyLoading(state, { dataKey }))
  const bannerOwnership = useSelector((state) => customerSelectors.getBannerOwnership(state, { customerId }))
  const customer = useSelector((state) => customerSelectors.customerFromUrl(state, { customerId }))
  const region = useSelector((state) => territorySelectors.getRegion(state, { customerId }))
  const { AWR13, WTD } = useSelector((state) => customerSelectors.getPerfData(state, { customerId }))
  const orderComplianceThresholds = useSelector((state) =>
    customerSelectors.orderComplianceThresholds(state, { customerId })
  )

  useEffect(() => {
    console.log(customerId, sectorLevel)
    if (customerId && sectorLevel === SECTOR_LEVELS.CUSTOMER) {
      setError()
      getCustomer(customerId, dataKey).catch((e) => setError(e))
    }
  }, [customerId, getCustomer])

  useEffect(() => {
    addAction({
      getCreateButton: () =>
        !customer?.saqCreationDisabled && !isLoading ? (
          <Button key={customerId} disabled={isLoading} onClick={() => navigate('create')} primary>
            New Order
          </Button>
        ) : null
    })
  }, [customerId, customer?.saqCreationDisabled, isLoading])

  const getContent = () => {
    if (isLoading) return <LoadingCard dataKey={dataKey} />

    if (error) return <EmptyState title={getErrorMessage(error)} />

    if (!customer) return <EmptyState title={'No customer available'} />

    const isCreditBlocked = customer.creditBlock === 'Y'
    const isCentrallyBlocked = customer.centralOrderBlock === '01'

    const isOrderApprovalExempt =
      customer.orderApprovalExempt || bannerOwnership?.orderApprovalExempt || region?.orderApprovalExempt

    if (customer.saqCreationDisabled && (isCreditBlocked || isCentrallyBlocked))
      return <GlobalAlert>Account is {isCentrallyBlocked ? 'centrally' : 'credit'} blocked</GlobalAlert>

    return (
      <>
        {!isOrderApprovalExempt && (
          <GlobalAlert warning>
            <p>
              <small>AWR13: {AWR13 || '0'}</small>
              <br />
              <small>WTD: {WTD || '0'}</small>
            </p>
            <p>
              <small>{orderComplianceThresholds.emergency} cartons left to order before reaching threshold</small>
              <br />
              <small>{orderComplianceThresholds.saq} cartons left to SAQ before reaching threshold</small>
            </p>
            <p>
              <small>
                If over threshold, the authorization email will be sent to <u>{customer.primaryContact?.email}</u>. If
                this email address is incorrect, please contact the Marketing Systems team.
              </small>
            </p>
          </GlobalAlert>
        )}
        {customer.orders?.length ? (
          <SAQTable orders={customer.orders} linkToDetails={(orderId) => navigate(orderId)} />
        ) : (
          <EmptyState title="Nothing yet" subtitle="There are no SAQs for this customer." />
        )}
      </>
    )
  }

  return (
    <>
      <Container>{getContent()}</Container>
      <Outlet />
    </>
  )
}

SAQList.propTypes = {
  getCustomer: func
}

export default connect(null, { getCustomer })(SAQList)
